import { TFunction } from 'i18next';
import { UseTranslation, useTranslation as useTrans } from 'next-i18next';
import { useCallback } from 'react';

export const useTranslation: any = (...params: Parameters<UseTranslation>) => {
  const { t, ...rest } = useTrans(...params);
  const newT: TFunction = useCallback(
    (key: string, options: any) => {
      const keys = key.split('.');
      const transformedKey = `${keys[0]}::${keys.join('.')}`;
      return t(transformedKey, options);
    },
    [t]
  );
  return { t: newT, ...rest };
};
