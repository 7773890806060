import React, { forwardRef } from 'react';
import { SvgProps } from './icon.types';

const CloseIcon = forwardRef(
  ({ title, fillColor = '#2D3049', ...props }: SvgProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg ref={svgRef} height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
        {title && <title>{title}</title>}
        <path
          d="M19.5 3l1.41 1.42-7.54 7.54 7.55 7.54-1.42 1.41-7.54-7.54-7.55 7.54L3 19.52l7.54-7.55L3 4.4 4.41 3l7.55 7.54L19.5 3z"
          fill={fillColor}
          fillRule="evenodd"
        />
      </svg>
    );
  }
);

export { CloseIcon };
