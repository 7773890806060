import { isServer } from './server';

export function handleScrollTop() {
  if (isServer()) {
    return;
  }
  window.scrollTo({ behavior: 'auto', top: 0 });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getHost(req?: any) {
  // Force https
  const protocol = 'https';
  let plcHostname = 'www.pleinchamp.com';
  if (typeof window !== 'undefined') {
    const {
      location: { hostname },
    } = window;
    plcHostname = hostname;
  } else if (req && req.get) {
    plcHostname = req.get('host');
  }

  return `${protocol}://${plcHostname}`;
}
