import { createContext, RefObject } from 'react';
import { GenericModalProps } from '@layout/components/Modal/Modal.types';

export const GenericModalContext = createContext<RefObject<GenericModalProps> | null>(null);

type GeneralContextProps = {
  host: string;
  windowWidth?: number;
  searchQuery?: string;
  setSearchQuery?: (key: string) => void;
};

export const GeneralContext = createContext<GeneralContextProps>({ host: '' });
