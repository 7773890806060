import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import classnames from 'classnames';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  wrapperClassName?: string;
}

const BasicButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, wrapperClassName, onClick, type, children, ...rest }, buttonRef) => {
    const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (onClick && (event.key === 'Enter' || event.key === ' ')) {
        onClick(event as unknown as React.MouseEvent<HTMLButtonElement>);
      }
    };
    const wrapperClasses = classnames('basic-button-wrapper', wrapperClassName);
    const rootClassname = classnames('basic-button', className);

    return (
      <button
        ref={buttonRef}
        className={wrapperClasses}
        onClick={onClick}
        onKeyDown={onKeyDown}
        type={type || 'button'}
        {...rest}
      >
        <span className={rootClassname} tabIndex={-1}>
          {children}
        </span>
      </button>
    );
  }
);

export { BasicButton };
