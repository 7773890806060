/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CACT_CATEGORIES, TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import { PlcCustomATInfo } from './TagPlan.types';

// Based on : https://developers.atinternet-solutions.com/javascript-fr/bien-commencer-javascript-fr/initialisation-du-tracker-javascript-fr/
export type ATTrackerOptions = { secure: boolean };

// From https://developers.atinternet-solutions.com/javascript-fr/contenus-javascript-fr/pages-javascript-fr/
export type ATPageInfo = {
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  customObject?: any;
};

export type ATUserInfo = { category: null | string };

// From https://developers.atinternet-solutions.com/javascript-fr/contenus-javascript-fr/clics-javascript-fr/
export type ATClickInfo = {
  elem?: any;
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  type?: 'exit' | 'download' | 'action' | 'navigation';
  customObject?: any;
};

type ATTagObject = {
  click: { send: (info?: ATClickInfo) => {} };
  dispatch: () => {};
  page: { set: (info?: ATPageInfo) => {} };
  identifiedVisitor: {
    set: (info?: ATUserInfo) => {};
    unset: () => {};
  };
};

export type PlcATPageInfo = Omit<ATPageInfo, 'name' | 'level2'> & PlcCustomATInfo;
export type PlcATClickInfo = Omit<ATClickInfo, 'name' | 'level2'> & PlcCustomATInfo;

const defaultOptions: Partial<ATTrackerOptions> = { secure: true };

export class TagPlan {
  private isInstanciated = false;

  private enabled = false;

  private tagObject?: ATTagObject;

  public init(options = defaultOptions) {
    const hasCookieBeenConsented = TagCommanderService.getIABConsentString();
    if (this.isInstanciated || !window || !hasCookieBeenConsented) {
      return;
    }
    if (!TagCommanderService.isCookieConsentByCategorieAccepted(CACT_CATEGORIES.PLC_CAT_MESURE_AUDIENCE)) {
      return;
    }
    try {
      // @ts-ignore
      this.tagObject = new window.ATInternet.Tracker.Tag(options);
      this.isInstanciated = true;
      this.enabled = true;
      return;
    } catch (err: any) {
      // Nothing
      console.error(err);
    }
  }

  public tagPage({
    name,
    level2,
    ...rest
  }: Omit<PlcATPageInfo, 'tagPageNameI18nArray'> & { name: ATClickInfo['name'] }) {
    const interval = setInterval(() => {
      if (!this.tagObject || !this.enabled) {
        return;
      }
      this.tagObject.page.set({
        level2: level2 ? level2.toString() : undefined,
        name,
        ...rest,
      });
      this.tagObject.dispatch();
      clearInterval(interval);
    }, 800);
  }

  public tagClick({
    name,
    type = 'action',
    level2,
    ...rest
  }: Omit<PlcATClickInfo, 'tagPageNameI18nArray'> & { name: ATClickInfo['name'] }) {
    if (!this.tagObject || !this.enabled) {
      return;
    }
    this.tagObject.click.send({
      level2: level2 ? level2.toString() : undefined,
      name,
      type,
      ...rest,
    });
  }

  public tagVisitor(visitor: ATUserInfo) {
    if (!this.tagObject || !this.enabled) {
      return;
    }
    if (this.tagObject.identifiedVisitor) {
      try {
        this.tagObject.identifiedVisitor.set(visitor);
      } catch (error) {
        console.log('ATInternet error tagVisitor - visitor : ', visitor, ' tagObject ', this.tagObject, error);
      }
    }
  }

  public unsetVisitor() {
    if (!this.tagObject || !this.enabled) {
      return;
    }
    if (this.tagObject.identifiedVisitor) {
      this.tagObject.identifiedVisitor.unset();
      this.tagObject.dispatch();
    }
  }
}

export const TagPlanService = new TagPlan();
