import { TFunction } from 'i18next';
import kebabCase from 'lodash/kebabCase';
import {
  CattleRates,
  Market,
  Product,
  ProductCategoryCode,
  ProductType,
  Rates,
  UserProductType,
} from 'pleinchamp-api-client';
import { plcDayjs } from '@utils/date';
import { urlEnum } from '@utils/url';
import { Dayjs } from 'dayjs';
import { CattleProductType, StockDetailsPath, StocksHomeTabs, StockSource } from './Stocks.types';
import { StocksAnalysisTab } from './StocksAnalysisTab.types';

const ORDER_E = { order: 1, value: 'E' };
const ORDER_U = { order: 2, value: 'U' };
const ORDER_R = { order: 3, value: 'R' };
const ORDER_O = { order: 4, value: 'O' };
const ORDER_P = { order: 5, value: 'P' };

const ORDER_MALE = { order: 1, value: 'M' };
const ORDER_FEMALE = { order: 2, value: 'F' };

export function getStockTypeCodeFromUrl(stockType: StocksHomeTabs | StockDetailsPath) {
  switch (stockType) {
    case StockDetailsPath.Crops:
    case StocksHomeTabs.Crops:
      return ProductCategoryCode.Crops;
    case StockDetailsPath.CattleFood:
    case StocksHomeTabs.CattleFood:
      return ProductCategoryCode.CattleFood;
    case StockDetailsPath.Pork:
    case StocksHomeTabs.Pork:
      return ProductCategoryCode.Pork;
    case StockDetailsPath.Energies:
    case StocksHomeTabs.Energies:
      return ProductCategoryCode.Energies;
    case StockDetailsPath.Cattle:
    case StocksHomeTabs.Cattle:
      return ProductCategoryCode.Cattle;
    case StockDetailsPath.TermMarkets:
      return ProductCategoryCode.CommoditiesFutures;
    default: {
      const switchTest: never = stockType;
      return switchTest;
    }
  }
}

const mapStockToSource = {
  [ProductCategoryCode.Cattle]: StockSource.FMBV,
  [ProductCategoryCode.CattleFood]: StockSource.Agritel,
  [ProductCategoryCode.Crops]: StockSource.Agritel,
  [ProductCategoryCode.Pork]: StockSource.Agritel,
  [ProductCategoryCode.Energies]: StockSource.Agritel,
  [ProductCategoryCode.CommoditiesFutures]: StockSource.FinanceAgri,
};

export function setTableSourceFromCategory(productCategoryCode: ProductCategoryCode | undefined) {
  const source = productCategoryCode && mapStockToSource[productCategoryCode];
  return `stocks.home.source.${source}`;
}

export function getUrlStockTypeString(productCategoryCode: ProductCategoryCode) {
  switch (productCategoryCode) {
    case ProductCategoryCode.Crops:
      return StockDetailsPath.Crops;
    case ProductCategoryCode.CattleFood:
      return StockDetailsPath.CattleFood;
    case ProductCategoryCode.Pork:
      return StockDetailsPath.Pork;
    case ProductCategoryCode.Energies:
      return StockDetailsPath.Energies;
    case ProductCategoryCode.Cattle:
      return StockDetailsPath.Cattle;
    case ProductCategoryCode.CommoditiesFutures:
      return StockDetailsPath.TermMarkets;
    default: {
      const switchTest: never = productCategoryCode;
      return switchTest;
    }
  }
}

export function getIdFromParam(param?: string) {
  if (!param) return undefined;
  const regex = /^(\d*)-(.*)$/;
  const match = param.match(regex);
  return match ? parseInt(match[1], 10) : undefined;
}

export function formatProductTypeForUrl(productType: ProductType) {
  return [[productType.id?.toString(), kebabCase(productType.name)].join('-')];
}

export function getStockDetailRouteParams(productType: ProductType, market?: Market) {
  const productParams = formatProductTypeForUrl(productType);

  const href = `${urlEnum.stocks}/[stockType]/[...productParams]`;
  if (market && productType.productCategoryCode === ProductCategoryCode.Cattle) {
    productParams.push([market.id.toString(), kebabCase(market.marketName)].join('-'));
  }
  const as = `${urlEnum.stocks}/${getUrlStockTypeString(productType.productCategoryCode)}/${productParams.join('/')}`;

  return {
    as,
    href,
  };
}

export function extractMarketReferenceProductFromProductType(productType?: ProductType) {
  if (!productType) {
    return undefined;
  }
  const { marketReference, products } = productType;
  if (!marketReference) {
    return undefined;
  }
  return products.find(p => p.market?.id === marketReference.id);
}

export function getSalesRate(rates: Rates): Rates & { total: number; salesRate: number } {
  const { sold, unsold } = rates;
  const total = sold + unsold;
  const salesRate = total > 0 ? Math.round((sold * 100) / total) : 0;
  return {
    salesRate,
    sold,
    total,
    unsold,
  };
}

export function getUserProductTypeMarketId(userProductType: UserProductType) {
  if (userProductType.productCategoryCode === ProductCategoryCode.Cattle) {
    return userProductType.products[0] ? userProductType.products[0].market?.id : userProductType.marketReference?.id;
  }

  return undefined;
}

function getCattleTypeRate(cattleTypeId: number, cattleRates?: CattleRates[]) {
  return (
    (cattleRates && cattleRates.find(cr => cr.cattleTypeId === cattleTypeId)?.rates) || {
      sold: 0,
      total: 0,
      unsold: 0,
    }
  );
}

/**
 * A partir de la conformation fourni en paramètre (U, R, O ou P) on retourne
 * la valeur int correspondant à l'odre attendu.
 */
function getOrderValueByConformation(conformation: string | undefined) {
  switch (conformation) {
    case ORDER_E.value:
      return ORDER_E.order;
    case ORDER_U.value:
      return ORDER_U.order;
    case ORDER_R.value:
      return ORDER_R.order;
    case ORDER_O.value:
      return ORDER_O.order;
    case ORDER_P.value:
      return ORDER_P.order;
    default:
      return 1;
  }
}

/**
 * Compartor for conformation.
 *
 * @param conformationA
 * @param conformationB
 * @returns -1 if a is less than b, 1 wether a is greater than b, 0 a must be equal to b
 */
function compareCattleByConformation(productA: Product, productB: Product) {
  const orderA = getOrderValueByConformation(productA.cattleDetail?.conformation);
  const orderB = getOrderValueByConformation(productB.cattleDetail?.conformation);

  if (orderA < orderB) {
    return -1;
  }
  if (orderA > orderB) {
    return 1;
  }
  // a must be equal  to b
  return 0;
}

function getOrderValueBySex(sex: string | undefined) {
  switch (sex) {
    case ORDER_MALE.value:
      return ORDER_MALE.order;
    case ORDER_FEMALE.value:
      return ORDER_FEMALE.order;
    default:
      return 1;
  }
}

function compareSexMaleFemale(productA: Product, productB: Product) {
  const orderA = getOrderValueBySex(productA.cattleDetail?.sex);
  const orderB = getOrderValueBySex(productB.cattleDetail?.sex);

  if (orderA < orderB) {
    return -1;
  }
  if (orderA > orderB) {
    return 1;
  }
  // a must be equal  to b
  return 0;
}

function compareBreedAlphabetic(productA: Product, productB: Product) {
  if (
    productA.cattleDetail !== undefined &&
    productB.cattleDetail !== undefined &&
    productA.cattleDetail?.breed < productB.cattleDetail?.breed
  ) {
    return -1;
  }
  if (
    productA.cattleDetail !== undefined &&
    productB.cattleDetail !== undefined &&
    productA.cattleDetail?.breed > productB.cattleDetail?.breed
  ) {
    return 1;
  }
  return 0;
}

export function getCattleTables(products: Product[], cattleRates?: CattleRates[]) {
  // Need to sort also by conformation
  return products
    .sort(compareCattleByConformation)
    .sort(compareSexMaleFemale)
    .sort(compareBreedAlphabetic)

    .reduce<CattleProductType[]>((previous, p) => {
      if (p.cattleDetail) {
        const { typeId } = p.cattleDetail;
        const cattleProductTypeIndex = previous.findIndex(cpt => cpt.cattleTypeId === typeId);
        if (cattleProductTypeIndex > -1) {
          previous[cattleProductTypeIndex].products.push(p);
        } else {
          previous.push({ cattleTypeId: typeId, products: [p], rates: getCattleTypeRate(typeId, cattleRates) });
        }
      }
      return previous;
    }, []);
}

export function getTermLastUpdate(productType: ProductType) {
  return productType.products.reduce<Dayjs | undefined>((previous, p) => {
    const quoteDate = plcDayjs(p.quote.date);
    return !previous || quoteDate.isAfter(previous) ? quoteDate : previous;
  }, undefined);
}

export function getDetailsPageInfos(
  t: TFunction,
  loading?: boolean,
  productCategoryCode?: ProductCategoryCode,
  market?: Market,
  productType?: ProductType
) {
  if (loading) {
    return {
      metas: {
        metaTitleI18n: '',
      },
      title: '',
    };
  }
  const options = {
    market: market?.marketName || '',
    product: productType?.name || '',
    type: productCategoryCode ? t(`stocks.product-category.${productCategoryCode}`) : '',
  };
  const defaultMetas = {
    metaDescI18n: 'stocks.meta-desc',
    metaDescOptions: options,
    metaTitleI18n: 'stocks.meta-title',
    metaTitleOptions: options,
  };
  switch (productCategoryCode) {
    case ProductCategoryCode.Cattle:
      return {
        metas: {
          ...defaultMetas,
          metaDescI18n: 'stocks.cattle.meta-desc',
          metaTitleI18n: 'stocks.cattle.meta-title',
        },
        title: t('stocks.cattle.title', options),
      };
    case ProductCategoryCode.CommoditiesFutures:
      return {
        metas: { ...defaultMetas, metaDescI18n: 'stocks.term.meta-desc', metaTitleI18n: 'stocks.term.meta-title' },
        title: options.product,
      };
    case ProductCategoryCode.Pork:
      return {
        metas: defaultMetas,
        title: t('stocks.pork.title', options),
      };
    default:
      return {
        metas: defaultMetas,
        title: t('stocks.title', options),
      };
  }
}

export function transformAnalyseTabToUrl(analyseTab: StocksAnalysisTab): string {
  return analyseTab
    .toLocaleLowerCase()
    .trim()
    .replace(/\s+/g, '-');
}
