import cookies from 'js-cookie';
import {Session} from 'pleinchamp-api-client';
import React, {ComponentProps, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AuthState, defaultState} from '@auth/business/Auth.store';
import {TextProps} from '@components/Text/Text.types';
import {MenuLink} from '@layout/components/MenuLink/MenuLink.component';
import {NotificationsTabEnum} from '@notifs/business/Notifications.type';
import {SettingsTabEnum} from '@settings/business/Settings.type';
import {ProfileTabEnum} from '@user/business/User.enums';
import {isCguAccepted, userSelectors} from '@user/business/User.store';
import {usePlcRouter} from '@utils/customHooks';
import {safeJsonParse} from '@utils/strings';
import {getLinkProps, getPreferencesLinkProps, urlEnum} from '@utils/url';
import {PlcNextPage, PlcNextPageContext} from 'features/business/app.types';
import {authSelectors} from './Auth.selectors';
import { getCookie } from 'cookies-next';

export const LOGOUT_KEY = 'plclogout';

export enum SignUpProfile {
  member = 'member',
  pro = 'pro',
}

export function getAuthState(session: Session): AuthState {
  return {
    cookie: `${session.name}=${session.identifier};`,
    csrfToken: session.csrfToken,
    isAuthenticated: true,
    sessionId: session.identifier,
  };
}

export function getAuthStateFromCookie(token?: string) {
  if (token) {
    try {
      const session = safeJsonParse(token);
      return getAuthState(session);
    } catch (err: any) {
      console.error(err);
    }
  }

  return defaultState;
}

export function withAuthSync<P>(WrappedComponent: PlcNextPage<P>) {
  return (props: ComponentProps<typeof WrappedComponent>) => {
    const {asPath, pathname, push} = usePlcRouter();
    const syncLogout = (event: StorageEvent) => {
      if (event.key === LOGOUT_KEY) {
        push(urlEnum.journal);
      }
    };

    const token = cookies.get('token');
    const isAuthenticatedFromToken = getAuthStateFromCookie(token).isAuthenticated;
    const isAuthenticatedFromStore = useSelector(authSelectors.isAuthenticated);
    const isAuthenticated = isAuthenticatedFromStore || isAuthenticatedFromToken;
    const user = useSelector(userSelectors.getUser);
    const isInit = useSelector(userSelectors.isInit);
    const cguAccepted = user ? isCguAccepted(user) : false;

    useEffect(() => {
      if (!isAuthenticated) {
        push(`${urlEnum.login}?callback=${asPath}`);
      } else if (isInit && !cguAccepted && pathname !== urlEnum.validateTos) {
        push(`${urlEnum.validateTos}?callback=${asPath}`);
      }
    }, [isAuthenticated, cguAccepted, isInit]);

    useEffect(() => {
      window.addEventListener('storage', syncLogout);

      return () => {
        window.removeEventListener('storage', syncLogout);
        window.localStorage.removeItem(LOGOUT_KEY);
      };
    }, []);

    return <><WrappedComponent {...(props as any)} /></>;
  };
}

export function getAuthFromContext(ctx: PlcNextPageContext) {
  let token: string | undefined;
  try {
    const tokenCookieString = getCookie('token', ctx);
    token = tokenCookieString ? JSON.parse(tokenCookieString) : undefined;
  } catch {
    console.error('Erreur lors de la récupération du cookie token');
    token = undefined;
  }

  return getAuthStateFromCookie(token);
}

export function buildProfileMenus(
  route: string,
  variant: TextProps['variant'] = 'h5',
  onClick?: () => void,
  className?: string
) {
  const generalPreferencesLinkProps = getPreferencesLinkProps(SettingsTabEnum.general);
  return [
    <MenuLink
      key="profile"
      active={RegExp(urlEnum.profile).test(route)}
      className={className}
      i18nKey="common.profile.menu-title"
      linkProps={getLinkProps(`${urlEnum.profile}/[prefType]`, { prefType: ProfileTabEnum.profile })}
      onClick={onClick}
      variant={variant}
    />,
    <MenuLink
      key="prefs"
      active={RegExp(urlEnum.preferences).test(route)}
      i18nKey="common.preferences.menu-title"
      linkProps={generalPreferencesLinkProps}
      onClick={onClick}
      variant={variant}
    />,
    <MenuLink
      key="notifs"
      active={RegExp(urlEnum.notifications).test(route)}
      i18nKey="common.notifications.menu-title"
      linkProps={getLinkProps(`${urlEnum.notifications}/[tab]`, { tab: NotificationsTabEnum.meteo })}
      onClick={onClick}
      variant={variant}
    />,
  ];
}
