// eslint-disable-next-line simple-import-sort/sort
import dayjs, { ConfigType } from 'dayjs';
import trim from 'lodash/trim';
// Locales MUST BE imported after plugins
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfTheYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/fr';
// dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfTheYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale('fr');

dayjs.tz.setDefault('Europe/Paris');

export const plcDayjs = dayjs;

export type DateType = ConfigType;

export function formatDateToIso(d: DateType) {
  const dayjsDate = plcDayjs(d);
  return dayjsDate.toISOString();
}

function capitalize(s: string) {
  if (s.length > 1) return `${s[0].toUpperCase()}${s.substring(1)}`;
  return s.toUpperCase();
}

function formatMonthShort(d: DateType, locale = 'fr'): string {
  const dayjsDate = plcDayjs(d);
  if (locale === 'fr') {
    const month = dayjsDate.month();
    // mars, mai, juin, aout => full name
    if ([2, 4, 5, 7].includes(month)) {
      return dayjsDate.format('MMMM');
    }
    // janvier, février, juillet, septembre => 4 letters + .
    if ([0, 1, 6, 8].includes(month)) {
      return `${dayjsDate.format('MMMM').substring(0, 4)}.`;
    }
    // 3 letters + .
    return `${dayjsDate.format('MMMM').substring(0, 3)}.`;
  }
  return dayjsDate.format('MMM[.]');
}

// ex: 25/06
export function formatDateDayAndMonth(d: DateType) {
  const dayjsDate = plcDayjs(d);
  return dayjsDate.format('DD/MM');
}

// ex: 25/06/1984
export function formatDateCompact(d: DateType) {
  const dayjsDate = plcDayjs(d);
  return dayjsDate.format('DD/MM/YYYY');
}

// ex: 25/06/1984 - S48
export function formatDateCompactWithWeekOfYear(d: DateType) {
  const dayjsDate = plcDayjs(d);
  return `${formatDateCompact(dayjsDate)} - S${dayjsDate.format('ww')}`;
}

// ex: Mardi 29/10/2019
export function formatDateWithDay(d: DateType) {
  const dayjsDate = plcDayjs(d);
  const day = dayjsDate.format('dddd');
  return `${capitalize(day)} ${formatDateCompact(d)}`;
}

// ex : 15 Fév
export function formatDayMonth(d: DateType) {
  const dayjsDate = plcDayjs(d);
  return `${dayjsDate.format('D')} ${capitalize(formatMonthShort(d))}`;
}

// ex : 3h35
export function formatDateToTime(d: DateType) {
  const dayjsDate = plcDayjs(d);
  return dayjsDate.format('HH[h]mm');
}

// ex : 3h
export function formatDateToHour(d: DateType) {
  const dayjsDate = plcDayjs(d);
  return dayjsDate.format('H[h]');
}

// ex : Mardi 29/10/2019 - 3h35
export function formatDateToDateTime(d: DateType) {
  return `${formatDateWithDay(d)} - ${formatDateToTime(d)}`;
}

// ex: Ven. 6 Oct.
export function formatDateToFullText(d: DateType) {
  const dayjsDate = plcDayjs(d);
  const day = dayjsDate.format('ddd');
  return `${capitalize(day)} ${dayjsDate.format('D')} ${capitalize(formatMonthShort(dayjsDate))}`;
}

// ex: Aujourd'hui ou Ven. 6 Oct. 2020
export function formatDateToFullTextWithToday(d: DateType, today: string, withYear = true) {
  const dayjsDate = plcDayjs(d);
  return dayjsDate.isSame(plcDayjs(), 'day')
    ? today
    : trim(`${formatDateToFullText(d)} ${withYear ? dayjsDate.format('YYYY') : ''}`);
}

// ex: Vendredi 6 Oct.
export function formatDateFullDayAndDayNumberAndShortMonth(d: DateType) {
  const dayjsDate = plcDayjs(d);
  const day = capitalize(dayjsDate.format('dddd'));
  const month = formatMonthShort(dayjsDate);
  return `${day} ${dayjsDate.format('D')} ${capitalize(month)}`;
}

// ex: Mar 6
export function formatDateShortDayAndDayNumber(d: DateType) {
  const dayjsDate = plcDayjs(d);
  const day = capitalize(dayjsDate.format('ddd').replace(/(\.)$/, ''));
  return `${day} ${dayjsDate.format('DD')}`;
}

// ex: Janv. 19
export function formatShortMonthWithYear(d: DateType) {
  const dayjsDate = plcDayjs(d);
  const month = formatMonthShort(dayjsDate);
  const year = dayjsDate.format('YY');
  return `${capitalize(month)} ${year}`;
}

// ex: Mardi
export function formatFullDay(d: DateType) {
  const dayjsDate = plcDayjs(d);
  const day = dayjsDate.format('dddd');
  return capitalize(day);
}

// ex: 30 juin 2020
export function formatFullDate(d: DateType) {
  const dayjsDate = plcDayjs(d);
  return `${dayjsDate.format('D MMMM YYYY')}`;
}
// ex: 30 juin
export function formatDateWithoutYear(d: DateType) {
  const dayjsDate = plcDayjs(d);
  return `${dayjsDate.format('D MMMM')}`;
}
