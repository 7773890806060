import { ServiceRatingCode } from 'pleinchamp-api-client';

export enum MeadowTabEnum {
  cumulatedProduction = 'production-cumulee',
  productionDynamic = 'dynamique-de-production',
}

export enum CustomServiceRatingCode {
  All = 'allRatings',
}

export type AllServiceRatingCode = ServiceRatingCode | CustomServiceRatingCode;
