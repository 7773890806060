import { urlEnum } from '@utils/url';

export enum DeviceEnum {
  Mobile = 'm',
  Desktop = 'd',
  Tablet = 't',
}

export enum ConnectionStateEnum {
  ANONYMOUS = 'ANONYME',
  CAC = 'CAC',
  SEC = 'SEC',
  FREE = 'FREE',
  OTHERS = 'AUTRES',
}

export type TcVarsComponentProps = {
  page_name?: urlEnum | string;
  page_url?: string;
  niveau3: string;
};

export type TcVarsUserOptions = TcVarsComponentProps & {
  statut_utilisateur: boolean;
  etat_de_connexion: ConnectionStateEnum;
};

export type TcVarsOptions = TcVarsUserOptions & {
  env_working: string;
  env_device: DeviceEnum;
  env_name_site: string;
};

export type eventOptions = {
  event: tcEvent;
  exclusions?: [];
};

export type tcEvent = {
  context: EventTarget | HTMLElement | null;
  label: string;
  variables?: {};
};
