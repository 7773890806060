import { Spacing } from './Spacing.style';
import { TextStyle } from './Text.style';
import { createTheme } from '@material-ui/core/styles';
import { PlcColor, PlcFonts } from './colors';
import { Dimensions } from './dimensions';

export const PlcMuiTheme = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        fontFamily: PlcFonts.Noto,
        width: '100%',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
      root: {
        fontFamily: PlcFonts.Noto,
        fontSize: `${TextStyle.tiny.fontSize}px`,
        lineHeight: `${TextStyle.tiny.lineHeight}px`,
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'inherit',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: `14px ${Spacing.m}px`,
      },
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${PlcColor.GREY_CLOUD_DARK}`,
        },
        borderRadius: 0,
        height: Dimensions.formInputHeigth,
      },
    },
    MuiTabs: {
      scrollButtons: {
        '& svg': {
          border: `1px solid ${PlcColor.GREY_CLOUD_MEDIUM}`,
          height: '50px',
          padding: '10px 5px',
          width: '40px',
        },
        '&:hover svg': { border: `1px solid ${PlcColor.BLUE_LAND}` },
        width: 'unset',
      },
    },
  },
  palette: {
    error: {
      main: PlcColor.RED_PEPPER,
    },
    primary: {
      main: PlcColor.RED_PEPPER,
    },
    secondary: {
      main: PlcColor.BLUE_LAND,
    },
  },

  typography: {
    body1: {
      fontFamily: PlcFonts.Noto,
    },
    body2: {
      fontFamily: PlcFonts.Noto,
    },
    caption: {
      fontFamily: PlcFonts.Noto,
    },
  },
});
