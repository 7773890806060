import React from 'react';
import { SvgProps } from './icon.types';

function ArrowNextIcon({ fillColor = '#FFF', ...props }: SvgProps) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.39 6L22 12l-5.61 6L15 16.513 18.285 13H2v-2h16.285L15 7.487 16.39 6z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export { ArrowNextIcon };
