// eslint-disable-next-line import/no-unresolved
import { Feature, Point } from 'geojson';
import {
  Content,
  ForecastParamCode,
  Forecasts,
  FreeField,
  Geopoint,
  InlineResponse2001,
  SummaryForecast,
} from 'pleinchamp-api-client';
import { IconSizes } from '@components/icons/icon.types';
import { PlcImgProps } from '@components/PlcImg/PlcImg.types';
import { PlcNotification } from '@notifs/types';
import { PlcPageContextProps } from 'features/business/app.types';
import { MeteoHomeTabs } from './business/Meteo.types';

export enum MeteoMetric {
  RainLevel = 'rain-level',
  MinMaxTemperature = 'min-max-temperature',
  WindSpeed = 'wind-speed',
  Sunrise = 'sunrise',
  Sunset = 'sunset',
  MoonPhase = 'moon-phase',
  Humidity = 'humidity',
  WindDirection = 'wind-direction',
}

export enum MoonPhase {
  NewMoon = 'new-moon',
  WaxingCrescent = 'waxing-crescent',
  FirstQuarter = 'first-quarter',
  WaxingGibbous = 'waxing-gibbous',
  FullMoon = 'full-moon',
  WaningGibbous = 'waning-gibbous',
  LastQuarter = 'last-quarter',
  WaningCrescent = 'waning-crescent',
}

export enum WeatherCode {
  ClearSkies = 1,
  PartlyCloudy = 2,
  MainlyCloudy = 3,
  Overcast = 4,
  Mist = 5,
  Rain = 6,
  LightDrizzle = 7,
  Snow = 8,
  RainAndSnow = 9,
  DrizzleAndRain = 10,
  RainShower = 11,
  HeavyThunderstorms = 12,
  SpringShower = 13,
  SnowShower = 14,
  RainAndSnowShower = 15,
  Thunderstorms = 16,
  HeatThunderstorms = 17,
  FreezingRain = 18,
  HailShower = 19,
  ThunderstormsWithHailShower = 20,
  Storm = 21,
  StrongWind = 22,
  Sandstorm = 23,
}
export type WeatherCodeIconProps = Omit<PlcImgProps, 'plcSrc' | 'alt'> & {
  weatherCode: number;
  variant?: IconSizes;
  isLoading?: boolean;
  night?: boolean;
};

export interface CityProperties {
  citycode: string;
  name: string;
  postcode: string;
  label?: string;
  city?: string;
  context?: string;
}

export interface Geolocation {
  type: 'Geolocation';
  geometry: null | undefined;
  properties: CityProperties;
}

export type PlcGeoFeature = Feature<Point, CityProperties>;

export interface StandardCity extends Omit<PlcGeoFeature, 'geometry'> {
  geometry: Geopoint;
}

export type City = StandardCity | Geolocation;

export interface ForecastAdditionalInfo {
  sunrise: Date;
  sunset: Date;
  moonPhase: MoonPhase;
}

export interface WeatherPageProps {
  lastMeteoCity?: City;
  city: City;
  forecasts: SummaryForecast;
  forecasts36Hours: Forecasts;
  forecasts15Days: Forecasts;
  notifications: PlcNotification[];
  defaultTab: MeteoHomeTabs;
  news: Content[];
  freeFieldContent: FreeField | undefined;
  paramCodes: ForecastParamCode[];
  servicesContent: InlineResponse2001;
}

export type WeatherContext = PlcPageContextProps<WeatherPageProps> & {
  geolocationError?: string;
  reversedCity?: StandardCity;
  additionalInfos?: ForecastAdditionalInfo;
  coordinates?: Geopoint;
};
