import { useDispatch } from 'react-redux';
import { AuthModalView, LayoutState } from '@layout/business/Layout.store';
import { PlcDispatch } from '@store/store';

export function useAuthModal() {
  const {
    layout: { showAuthModalView },
  } = useDispatch<PlcDispatch>();

  function showSignInModal(authModalProps?: LayoutState['authModalProps']) {
    showAuthModalView({ authModalProps, authModalView: AuthModalView.signIn });
  }

  function showForgottenPasswordModal() {
    showAuthModalView({ authModalView: AuthModalView.forgottenPassword });
  }

  function showForgottenPasswordSuccessModal(userEmail?: string) {
    showAuthModalView({ authModalView: AuthModalView.forgottenPasswordSuccess, userEmail });
  }

  function showForgottenEmailModal() {
    showAuthModalView({ authModalView: AuthModalView.forgottenEmail });
  }

  function hideAuthModal() {
    showAuthModalView();
  }

  return {
    hideAuthModal,
    showForgottenEmailModal,
    showForgottenPasswordModal,
    showForgottenPasswordSuccessModal,
    showSignInModal,
  };
}
