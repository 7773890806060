import { ActivityCode, InterestCode } from 'pleinchamp-api-client';

export enum SearchContentType {
  All = 'all',
  Article = 'article',
  Event = 'event',
  Service = 'service',
  Guide = 'guide',
}

export type SearchQueryParams = {
  query: string;
  contentType: SearchContentType;
  interests: InterestCode[];
  activities: ActivityCode[];
  pageNb?: number;
};

export const contentTypeInUrl: { [key in SearchContentType]: string } = {
  all: 'tous',
  article: 'actualites',
  event: 'evenements',
  guide: 'guides',
  service: 'services',
};

export const activityInUrl: { [key in ActivityCode]: string } = {
  bigCrops: 'grandes-cultures',
  breeding: 'elevage',
  fruitsVegetables: 'fruits-et-legumes',
  wine: 'vigne-et-vin',
};

export const interestInUrl: { [key in InterestCode]: string } = {
  agriculturalLife: 'vie-agricole',
  coursesMarket: 'cours-et-marches',
  environment: 'environnement',
  farmManagement: 'gestion-des-exploitations',
  innovation: 'innovation',
  international: 'international',
  machinery: 'machinisme',
  marketingOutlets: 'commercialisation-et-debouches',
  politics: 'politique',
  productionTechniques: 'techniques-de-production',
  societal: 'societal',
};

export enum FilterType {
  Themes,
  Activities,
}

export interface Filter {
  value: InterestCode | ActivityCode;
  active: boolean;
  type: FilterType;
}
