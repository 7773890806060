/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { initialTcVars } from './TagCommander.context';
import { AccountType } from 'pleinchamp-api-client';
import TC_Wrapper from 'react-tag-commander';
import { isServer } from '@utils/server';
import { ConnectionStateEnum, eventOptions, TcVarsOptions } from './TagCommander.types';

// https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#addeventlistener

// Singleton
const wrapper = TC_Wrapper.getInstance();

export const TC_CONTAINER_HEAD =
  process.env.NEXT_STATIC_TAG_COMMANDER_HEADER || 'https://cdn.tagcommander.com/6282/tc_Pleinchamp_23.js';
export const TC_CONTAINER_BODY =
  process.env.NEXT_STATIC_TAG_COMMANDER_BODY || 'https://cdn.tagcommander.com/6282/tc_Pleinchamp_25.js';

export enum EVENT_STATUS {
  // Consentement est déjà disponible
  tcLoaded = 'tcloaded',
  // La bannière est affichée/ réaffichée
  cmpUiShown = 'cmpuishown',
  // L'utilisateur a donné/changé son choix dans la bannière
  userActionComplete = 'useractioncomplete',
}

export enum CACT_STATUS_ENUM {
  // Consent was provided for the category.
  ON = 'on',
  // Consent was NOT provided for the category.
  OFF = 'off',
}

export enum CACT_CATEGORIES {
  PLC_CAT_PUBLICITAIRE = '1',
  PLC_CAT_RESEAUX_SOCIAUX = '2',
  PLC_CAT_MESURE_AUDIENCE = '3',
  IAB_INFO_TERMINAL = 'tcf2_1',
  IAB_PUB_STANDARD = 'tcf2_2',
  IAB_PUB_PERSONNALISEES = 'tcf2_3',
  IAB_MESURE_PERF_PUB = 'tcf2_4',
  IAB_DEV_AMELIORIER_PRODUITS = 'tcf2_5',
  IAB_PROFIL_PERSO_PUB = 'tcf2_6',
  IAB_PROFIL_PERSO_AFFICHER_CONTENU = 'tcf2_7',
  IAB_MESURER_PERF_CONTENU = 'tcf2_8',
  IAB_ETUDES_MARCHE = 'tcf2_9',
  IAB_SELECTION_CONTENU_PERSO = 'tcf2_10',
  IAB_SECURITE_DEBUG = 'tcf2_sf_1',
  IAB_DIFFUSER_TECHNIQUEMENT_PUB_CONTENU = 'tcf2_sf_2',
}

export function getUserConnectionState(accountType?: AccountType): ConnectionStateEnum {
  switch (accountType) {
    // Not logged in
    case undefined:
      return ConnectionStateEnum.ANONYMOUS;
    case AccountType.Free:
      return ConnectionStateEnum.FREE;
    case AccountType.Cac:
      return ConnectionStateEnum.CAC;
    case AccountType.Sec:
      return ConnectionStateEnum.SEC;
    case AccountType.BigAccount:
      return ConnectionStateEnum.OTHERS;
    case AccountType.Invited:
      return ConnectionStateEnum.OTHERS;
    default:
      return ConnectionStateEnum.OTHERS;
  }
}

class TagCommandServ {
  private isTcReady = false;

  private currentTcVar = initialTcVars;

  private isInit = false;

  private tcString = '';

  private eventStatus = null;

  private tcfPolicyVersion = 2;

  private tcNavigationEvent: EventTarget | HTMLElement | Window | null = null;

  private etat_de_connexion = ConnectionStateEnum.ANONYMOUS;

  private statut_utilisateur = false;

  private evenement_valeur = '';

  init(as: string, href: string): void {
    if (this.isInit || isServer() || this.isTcReady) {
      return;
    }
    this.currentTcVar = {
      ...this.currentTcVar,
      etat_de_connexion: this.etat_de_connexion,
      page_name: href,
      page_url: as,
      statut_utilisateur: this.statut_utilisateur,
    };
    console.log('TagCommandServ init - with this default value', this.currentTcVar);
    wrapper.setDebug(true);
    window.tc_vars = this.currentTcVar;
    // wrapper.setTcVars(this.currentTcVar); solution de contournement
    Promise.all([
      wrapper.addContainer('container_head', TC_CONTAINER_HEAD, 'head'),
      wrapper.addContainer('container_body', TC_CONTAINER_BODY, 'body'),
    ]).then(() => {
      this.isTcReady = true;
      console.log('TagCommandServ - tag com head and body added. TcReady is now', this.isTcReady);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const callback = (tcData: any, success: boolean) => {
        if ((success && tcData.eventStatus === 'tcloaded') || tcData.eventStatus === 'useractioncomplete') {
          // do something with tcData.tcString
          console.log('TrustCommander consent - eventStatus : ', tcData.eventStatus, ' - tcData : ', tcData);
          this.tcString = tcData.tcString;
          this.eventStatus = tcData.eventStatus;

          // remove the ourself to not get called more than once
          // eslint-disable-next-line no-underscore-dangle
          window.__tcfapi(
            'removeEventListener',
            2,
            (suc) => {
              if (suc) {
                // oh good...
                console.log('TrustCommander removeEventListener', tcData?.listenerId);
              }
            },
            tcData?.listenerId
          );
        }
      };

      // eslint-disable-next-line no-underscore-dangle
      window.__tcfapi('addEventListener', this.tcfPolicyVersion, callback);
    });

    this.isInit = true;
  }

  isStarted(): boolean {
    return this.isInit && this.isTcReady;
  }

  routeHandler(options?: eventOptions): void {
    try {
      wrapper.reloadAllContainers(options);
    } catch (e: any) {
      console.log(e);
    }
    if (options && options.event) {
      wrapper.triggerEvent(options.event.label, options.event.context, options.event.variables || {});
    }
    console.log('TagCommandServ routeHandler - Containers have been all reloaded and triggering page event', options);
  }

  updateTcVars(tcVars: TcVarsOptions): void {
    if (!this.isTcReady) {
      wrapper.setDebug(true);
    }
    wrapper.setTcVars(tcVars);
    this.currentTcVar = tcVars;
    console.log('TagCommandServ - updateTcVars done', tcVars);
  }

  getCurrentTcVars(): TcVarsOptions {
    return this.currentTcVar;
  }

  triggerEvent(eventLabel: string, eventTarget: HTMLElement, data: {}) {
    wrapper.triggerEvent(eventLabel, eventTarget, data);
    console.log(
      'TagCommandServ triggerEvent - eventLabel : ',
      eventLabel,
      ' event target : ',
      eventTarget,
      ' data : ',
      data
    );
  }

  getIABConsentString() {
    return this.tcString;
  }

  isConsented() {
    return this.eventStatus === EVENT_STATUS.tcLoaded || this.eventStatus === EVENT_STATUS.userActionComplete;
  }

  setTcNavigationEvent(event: EventTarget | HTMLElement | Window | null): void {
    this.tcNavigationEvent = event;
  }

  getTcNavigationEvent() {
    return this.tcNavigationEvent;
  }

  updateVisitorConnectionState(accountType: AccountType = AccountType.Free, isPro = false) {
    this.etat_de_connexion = getUserConnectionState(accountType);
    this.statut_utilisateur = isPro;
  }

  getVisitorConnectionState() {
    return this.etat_de_connexion;
  }

  showPrivacyCenter() {
    window.tC.privacyCenter.showPrivacyCenter();
    return false;
  }

  getEvenementValeur() {
    return this.evenement_valeur;
  }

  setEvenementValeur(evenement_valeur: string) {
    this.evenement_valeur = evenement_valeur;
  }

  updateCookieConsentByCategories(idCategorie: CACT_CATEGORIES, status: string) {
    window.localStorage.setItem('cact'.concat(idCategorie), status);

    window.cact('consent.update', {
      categories: {
        [idCategorie]: { status },
      },
    });
  }

  isCookieConsentByCategorieAccepted(idCategorie: CACT_CATEGORIES): boolean {
    // The only way to manage data within the callback...
    const key = 'cact'.concat(idCategorie);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.cact('consent.get', (result: any) => {
      const consent = result.consent.categories[idCategorie].status;
      window.localStorage.setItem(key, consent);
    });

    return window.localStorage.getItem(key) === CACT_STATUS_ENUM.ON;
  }

  showCactCookiesConsent() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.cact('consent.get', (result: any) => {
      console.log(result.consent.categories);
    });
  }
}

export const TagCommanderService = new TagCommandServ();
