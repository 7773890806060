import { BasicButton } from '../BasicButton/BasicButton.component';
import React, {ComponentPropsWithoutRef, forwardRef} from 'react';

import styles from './IconButton.module.scss';

type ButtonProps = ComponentPropsWithoutRef<'button'>

const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, type = 'button', ...rest }, buttonRef) => {
    return (
      <BasicButton
        ref={buttonRef}
        className={`${styles.iconWrapper} ${className}`}
        type={type as ButtonProps['type']}
        {...rest}
      />
    );
  }
);

export { IconButton };
