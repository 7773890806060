// Make sure to use the pleinchamp-api-client axios version and not the project's one
import { Configuration } from 'pleinchamp-api-client';
import { isServer } from '@utils/server';
import https from 'https';
import axios, { AxiosInstance } from 'pleinchamp-api-client/node_modules/axios';

// Set config defaults when creating the instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_STATIC_API_BASE_URL,
  httpsAgent: new https.Agent({
    keepAlive: true,
    rejectUnauthorized: process.env.NODE_ENV !== 'development',
  }),
  timeout: isServer() ? 15000 : 30000,
  withCredentials: true,
});

const ApiConfiguration = new Configuration({
  basePath: process.env.NEXT_STATIC_API_BASE_URL,
});

export { ApiConfiguration, axiosInstance };
