import { CACT_CATEGORIES, TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import { TagPlanService } from 'features/tagPlan/business/TagPlan.service';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AdvertService } from '@adverts/business/Advert.service';
import { RootState } from '@store/store';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { userSelectors } from '@user/business/User.store';
import { addScript } from '@utils/layout.utils';

function initATInternet() {
  addScript(
    process.env.NEXT_STATIC_AT_INTERNET_URL as string,
    [
      { attribute: 'async', value: 'true' },
      { attribute: 'data-cookieconsent', value: 'statistics' },
    ],
    { onLoad: () => TagPlanService.init() }
  );
}

function initConsentedService() {
  if (process.env.NEXT_STATIC_AT_INTERNET_STATUS !== 'disabled') {
    initATInternet();
  }
  AdvertService.setConsent();
}

function useThirdPartyScript() {
  const { activities, interests } = useSelector((state: RootState) => state.settings.preferences);
  const professions = useSelector(userSelectors.userProfessions);
  const isMobile = useIsBreakpointDown(Breakpoint.m);

  const {
    auth: { isAuthenticated },
  } = useSelector((state: RootState) => state);

  const initAdvertService = useCallback(() => {
    addScript(
      `https://ced.sascdn.com/tag/3053/smart.js?gdpr=1&gdpr_consent=${TagCommanderService.getIABConsentString()}`,
      [
        { attribute: 'async', value: 'true' },
        { attribute: 'data-cookieconsent', value: 'marketing' },
      ],
      { onLoad: () => AdvertService.init(professions, activities, interests, isMobile) }
    );
  }, [professions, activities, interests, isMobile]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (TagCommanderService.isConsented()) {
        console.log('TrustCommander post consent -> initialization of advert and consented services');
        TagCommanderService.isCookieConsentByCategorieAccepted(CACT_CATEGORIES.PLC_CAT_PUBLICITAIRE);
        if (process.env.NEXT_STATIC_ADS_STATUS !== 'disabled') {
          initAdvertService();
        }
        initConsentedService();
        clearInterval(timer);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      // Workaround to render ADS when user signIn or signOut.
      AdvertService.reRender();
      clearInterval(timer);
    }, 2000);

    return () => clearInterval(timer);
  }, [isAuthenticated]);
}

export { useThirdPartyScript };
