import { services, ServicesEffectsType, ServicesReducersType } from 'features/services/business/Services.store';
import { auth, AuthEffectsType, AuthReducersType } from '@auth/business/Auth.store';
import { layout, LayoutReducersType } from '@layout/business/Layout.store';
import { location, LocationEffectsType, LocationReducersType } from '@meteo/business/Location.store';
import { init, Models, RematchRootState, RematchStore } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import {
  pendingSettings,
  settings,
  SettingsEffectsType,
  SettingsReducersType,
} from '@settings/business/Settings.store';
import { user, UserEffectsType, UserReducersType } from '@user/business/User.store';

const models = {
  auth,
  layout,
  location,
  pendingSettings,
  services,
  settings,
  user,
};

const loading = createLoadingPlugin();

export const initializeStore = (initialState = {}) => {
  return init<typeof models>({
    models,
    plugins: [loading],
    redux: {
      initialState,
    },
  });
};

export type Store = RematchStore<typeof models>;

export interface PlcStore<M extends Models = typeof models> extends Omit<RematchStore<M>, 'dispatch'> {
  dispatch: PlcDispatch;
}

type PlcReducersDispatch = {
  layout: LayoutReducersType;
  auth: AuthReducersType;
  settings: SettingsReducersType;
  pendingSettings: SettingsReducersType;
  user: UserReducersType;
  location: LocationReducersType;
  services: ServicesReducersType;
};

type PlcEffectsDispatch = {
  auth: AuthEffectsType;
  settings: SettingsEffectsType;
  user: UserEffectsType;
  location: LocationEffectsType;
  services: ServicesEffectsType;
};

type PlcLoadingEffects = {
  [key in keyof PlcEffectsDispatch]: PlcLoadingStoreEffects<PlcEffectsDispatch[key]>;
};

type PlcLoadingStoreEffects<SE> = {
  [key in keyof SE]: boolean;
};

export type PlcDispatch = PlcReducersDispatch & PlcEffectsDispatch;

interface LoadingState<M extends Models> {
  loading: {
    global: boolean;
    models: { [k in keyof M]: boolean };
    effects: PlcLoadingEffects;
  };
}

export type RootState = RematchRootState<typeof models> & LoadingState<typeof models>;

type RemoveStateFromParams<T extends any[]> = T['length'] extends 0
  ? []
  : ((...b: T) => void) extends (state: any, ...b: infer I) => void
  ? I
  : [];

export type PlcReducers<S, R extends { [key: string]: (state: S, payload: any, meta?: any) => S }> = {
  [key in keyof R]: (...params: RemoveStateFromParams<Parameters<R[key]>>) => void;
};

export type PlcEffects<E extends (args: any) => any> = ReturnType<E>;
