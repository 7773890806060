export const TextStyle: { [key: string]: { fontSize: number; lineHeight: number } } = {
  big: { fontSize: 22, lineHeight: 34 },
  bold: { fontSize: 18, lineHeight: 34 },
  h1: { fontSize: 50, lineHeight: 55 },
  h2: { fontSize: 38, lineHeight: 42 },
  h3: { fontSize: 28, lineHeight: 32 },
  h4: { fontSize: 18, lineHeight: 24 },
  h5: { fontSize: 16, lineHeight: 22 },
  h6: { fontSize: 14, lineHeight: 18 },
  p: { fontSize: 18, lineHeight: 34 },
  'p-style-h2': { fontSize: 38, lineHeight: 42 },
  'p-style-h3': { fontSize: 28, lineHeight: 32 },
  small: { fontSize: 16, lineHeight: 26 },
  tiny: { fontSize: 12, lineHeight: 16 },
};
