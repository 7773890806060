import { useEffect } from 'react';
/**
 * Custom hooks
 * https://github.com/shadowwalker/next-pwa
 * https://github.com/shadowwalker/next-pwa/blob/master/examples/lifecycle/pages/index.js
 */
function usePwa() {
  // This hook only run once in browser after the component is rendered for the first time.
  // It has same effect as the old componentDidMount lifecycle callback.
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator && window.workbox !== undefined) {
      const wb = window.workbox;

      // When the service worker has update and waiting to install we force reload.
      const reloadApp = () => {
        wb.addEventListener('controlling', () => {
          // console.log('PWA - Old app version start force reload');
          window.location.reload();
        });
      };

      wb.addEventListener('waiting', reloadApp);

      wb.register();

      wb.addEventListener('install', () => {
        wb.skipWaiting();
      });
    }
  }, []);
}

export default usePwa;
