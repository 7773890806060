/* eslint-disable react/no-danger */
import { useThirdPartyScript } from './useThirdPartyScript.hook';
import Head from 'next/head';
import React, { ComponentProps, FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from '@auth/business/Auth.selectors';
import { useAuthModal } from '@auth/business/AuthModal.hook';
import { SignInForm } from '@auth/components/SignInForm/SignInForm.component';
import { useModal } from '@layout/business/Layout.utils';
import { useTranslation } from '@locales/useTranslation.hook';
import { RootState } from '@store/store';
import { isCguAccepted, isSsoFromCookie, useIsSSO, userSelectors } from '@user/business/User.store';
import { usePlcRouter } from '@utils/customHooks';
import { isServer } from '@utils/server';
import { urlEnum } from '@utils/url';
import dynamic from 'next/dynamic';
import styles from './Layout.module.scss';

const AuthModal = dynamic<Pick<ComponentProps<typeof SignInForm>, 'callbackUrl'>>(
  () => import('@auth/components/AuthModal/AuthModal.component').then((mod) => mod.AuthModal),
  { ssr: false }
);
const AuthModalTitle = dynamic<NonNullable<unknown>>(
  () => import('@auth/components/AuthModalTitle/AuthModalTitle.component').then((mod) => mod.AuthModalTitle),
  { ssr: false }
);
const Footer = dynamic<NonNullable<unknown>>(
  () => import('@layout/components/Footer/Footer.component').then((mod) => mod.Footer),
  {}
);

const Header = dynamic<NonNullable<unknown>>(
  () => import('@layout/components/Header/Header.component').then((mod) => mod.Header),
  {}
);

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const isProFromStore = useSelector(userSelectors.isPro);
  const isUserInit = useSelector(userSelectors.isInit);
  const isSSOFromStoreOrPage = useIsSSO();
  const isSSO = useMemo(() => {
    if (!isUserInit) {
      return isSsoFromCookie();
    }
    return isSSOFromStoreOrPage;
  }, [isUserInit, isProFromStore]);

  const { route, pathname, push, asPath } = usePlcRouter();
  const hideHeaderFooter = useMemo(() => ([urlEnum.meteoPlv, urlEnum.preview] as string[]).includes(route), [route]);

  const { isAuthModalOpened, authModalProps } = useSelector(({ layout }: RootState) => ({
    authModalProps: layout.authModalProps,
    isAuthModalOpened: layout.authModalView !== undefined,
  }));
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);
  const user = useSelector(userSelectors.getUser);
  const cguAccepted = useMemo(() => (user ? isCguAccepted(user) : false), [user]);

  const { hideAuthModal } = useAuthModal();
  const modal = useModal();
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (isAuthModalOpened) {
      modal.show({
        children: <AuthModal {...authModalProps} />,
        className: 'sign-in-modal',
        close: hideAuthModal,
        title: <AuthModalTitle />,
        withCustomFocusLock: true,
      });
    } else {
      modal.hide();
    }
  }, [isAuthModalOpened]);

  // Need to send full html content for server side (SEO)

  useEffect(() => {
    if (isUserInit && user && isAuthenticated && !cguAccepted && pathname !== urlEnum.validateTos) {
      push(`${urlEnum.validateTos}?callback=${asPath}`);
    }
  }, [isAuthenticated, cguAccepted, isUserInit, pathname, cguAccepted, user]);

  useThirdPartyScript();

  return (
    <>
      <Head>
        <title key="title">{t('common.app-name')}</title>
        <meta key="viewport" content="initial-scale=1.0, width=device-width" name="viewport" />
      </Head>
      <div className={`${!hideHeaderFooter ? styles.page : ''}`}>
        {!hideHeaderFooter && <Header />}
        <div id="arch_advert" />
        <div className={`${!hideHeaderFooter ? 'page-content' : undefined}`}>{children}</div>
        {!isSSO && !hideHeaderFooter && !isServer() && <Footer />}
      </div>
    </>
  );
};

export { Layout };
