import { TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import Link, { LinkProps } from 'next/link';
import React, { ComponentPropsWithRef, FC, forwardRef, MouseEvent, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from '@auth/business/Auth.selectors';
import { useAuthModal } from '@auth/business/AuthModal.hook';
import { useClassNames } from '@styles/breakpoints';
import { isExternal } from '@utils/url';

type Props = Omit<ComponentPropsWithRef<'a'>, 'href'> & {
  linkProps: LinkProps;
  withAuth?: boolean;
  niveau3?: string;
  onClick?: (...args: any[]) => any;
};

const PlcAnchor: FC<PropsWithChildren<Props>> = forwardRef(
  ({ children, linkProps, className, withAuth, niveau3 = '', onClick, ...rest }, anchorRef) => {
    const { href, as, ...restLinkProps } = linkProps;
    const isAuthenticated = useSelector(authSelectors.isAuthenticated);
    const { showSignInModal } = useAuthModal();

    const anchorClassName = useClassNames({ all: ['cursor-pointer', className] });

    function handleOnClick(event: MouseEvent<HTMLAnchorElement>) {
      onClick && onClick();

      const pageUrl = linkProps.href.toString();
      const pageName = linkProps.as?.toString();

      const currentTcVar = TagCommanderService.getCurrentTcVars();

      TagCommanderService.updateTcVars({
        ...currentTcVar,
        niveau3,
        page_name: pageName,
        page_url: pageUrl,
      });

      TagCommanderService.setTcNavigationEvent(event.target);

      if (!withAuth || isAuthenticated) {
        return;
      }
      event.preventDefault();
      showSignInModal({ callbackUrl: as?.toString() || href.toString() });
    }

    return isExternal(href) ? (
      <a
        ref={anchorRef}
        className={anchorClassName}
        href={href as string}
        onClick={handleOnClick}
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </a>
    ) : (
      <Link className={anchorClassName} as={as} href={href} {...restLinkProps} passHref prefetch={false} onClick={handleOnClick}>
          {children}
      </Link>
    );
  }
);

export { PlcAnchor };
