/* eslint-disable no-param-reassign */
import { ApiConfiguration, axiosInstance } from './api.conf';
import { CustomServiceRatingCode } from 'features/services/business/Services.enum';
import { TFunction } from 'i18next';
import { AuthApi as AuthApiClient } from 'pleinchamp-api-client/dist/src/auth/auth';
import { CompetitionGameAPi as CompetitionGameAPiClient } from 'pleinchamp-api-client/dist/src/competitionGame/competitionGame';
import {
  ActivityCode,
  ContentApi as ContentApiClient,
  ContentType,
  InterestCode,
  ProjectCode,
} from 'pleinchamp-api-client/dist/src/content/content';
import { ServiceRatingCode } from 'pleinchamp-api-client/dist/src/extService/extService';
import { MeteoApi as MeteoApiClient } from 'pleinchamp-api-client/dist/src/forecast/forecast';
import { LocationApi as LocationApiClient } from 'pleinchamp-api-client/dist/src/location/location';
import { CountyCode } from 'pleinchamp-api-client/dist/src/partner/partner';
import { ServiceApi as ServiceApiClient } from 'pleinchamp-api-client/dist/src/plcService/plcService';
import { RegistrationApi as RegistrationApiClient } from 'pleinchamp-api-client/dist/src/registration/registration';
import { SessionApi as SessionApiClient } from 'pleinchamp-api-client/dist/src/session/session';
import { TeamApi as TeamApiClient } from 'pleinchamp-api-client/dist/src/team/team';
import { UserApi as UserApiClient } from 'pleinchamp-api-client/dist/src/user/user';
import { MostViewedContent } from '@components/content/MostViewedContent/MostViewedContent.component';
import { SearchContentType } from '@search/business/search.types';
import { searchContent } from '@search/business/search.utils';
import { MarketApi } from '@stocks/business/Stocks.api';
import { PlcDispatch } from '@store/store';
import { fromCamelToKebab } from '@utils/strings';
import { getSlugLinkProps, urlEnum } from '@utils/url';

export const ContentApi = new ContentApiClient(ApiConfiguration, undefined, axiosInstance);
export const MeteoApi = new MeteoApiClient(ApiConfiguration, undefined, axiosInstance);
export const UserApi = new UserApiClient(ApiConfiguration, undefined, axiosInstance);
export const RegistrationApi = new RegistrationApiClient(ApiConfiguration, undefined, axiosInstance);
export const TeamApi = new TeamApiClient(ApiConfiguration, undefined, axiosInstance);
export const LocationApi = new LocationApiClient(ApiConfiguration, undefined, axiosInstance);
export const AuthApi = new AuthApiClient(ApiConfiguration, undefined, axiosInstance);
export const SessionApi = new SessionApiClient(ApiConfiguration, undefined, axiosInstance);
export const ServiceApi = new ServiceApiClient(ApiConfiguration, undefined, axiosInstance);
export const CompetitionGameApi = new CompetitionGameAPiClient(ApiConfiguration, undefined, axiosInstance);

/**
 * Add axios interceptors to :
 * - add auto refresh token when request (others than sessions) return 401
 */
let responseInterceptor: number;
export function updateAxiosInterceptors(dispatch: PlcDispatch) {
  if (typeof responseInterceptor !== 'undefined') {
    axiosInstance.interceptors.response.eject(responseInterceptor);
  }
  responseInterceptor = axiosInstance.interceptors.response.use(undefined, async error => {
    const originalRequest = error.config;
    const isSessionsRequest = /\/user\/sessions\//.test(originalRequest.url);

    if (error.response && error.response.status === 401 && !isSessionsRequest && !originalRequest.retry) {
      originalRequest.retry = true;

      try {
        await dispatch.auth.refreshSession();
        return axiosInstance(originalRequest);
      } catch (e: any) {
        console.error(e);
        throw error;
      }
    }
    return Promise.reject(error);
  });
}

export function getApiEnumLabel(
  key:
    | InterestCode
    | ActivityCode
    | ContentType
    | SearchContentType
    | ProjectCode
    | ServiceRatingCode
    | CountyCode
    | CustomServiceRatingCode,
  t: TFunction
) {
  const transformedKey = fromCamelToKebab(key);
  if (Object.values(InterestCode).includes(key as InterestCode)) {
    return t(`search.filter.theme.${transformedKey}`);
  }
  if (Object.values(ActivityCode).includes(key as ActivityCode)) {
    return t(`search.filter.activities.${transformedKey}`);
  }
  if (Object.values(ProjectCode).includes(key as ProjectCode)) {
    return t(`search.filter.projects.${transformedKey}`);
  }
  if (Object.values(CustomServiceRatingCode).includes(key as CustomServiceRatingCode)) {
    return t(`search.filter.ratings.${transformedKey}`);
  }
  if (Object.values(ServiceRatingCode).includes(key as ServiceRatingCode)) {
    return t(`search.filter.ratings.${transformedKey}`);
  }
  if (Object.values(CountyCode).includes(key as CountyCode)) {
    return t(`partners.county-code.${transformedKey}`);
  }
  return t(`search.filter.${transformedKey}`);
}

export function fetchLastActus(limit: number) {
  return searchContent({
    contentTypes: [ContentType.Article],
    isHighlighted: true,
    limit,
    offset: 0,
  }).then(r => r.contents);
}

export function fetchMostViewedNews(limit: number): Promise<MostViewedContent[]> {
  return ContentApi.getMostViewArticles(limit)
    .then(r =>
      r.data.map(({ article: { title, slug } }) => ({
        ...getSlugLinkProps(urlEnum.actu, slug),
        title,
      }))
    )
    .catch(() => []);
}

/**
 * Permet de récupérer les 5 dernieres analyses les plus récentes
 * @returns 5 most recent last analyses
 */
export function fetchLastAnalysis() {
  return MarketApi.getLastAnalysis()
    .then(r => r.data)
    .catch(() => []);
}
