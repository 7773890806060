import debounce from 'lodash/debounce';
import { useEffect } from 'react';
import { Constants } from '@utils/Constants';
import { isBrowser } from '@utils/server';

export const Dimensions = { formInputHeigth: 62, formInputMaxWidth: 327, headerHeightLarge: 96, headerHeightSmall: 80 };

const updateVh = debounce(() => {
  if (isBrowser()) {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}, Constants.TEN_FRAMES);

export function useUpdateVh() {
  useEffect(() => {
    updateVh();

    if (isBrowser()) {
      window.addEventListener('resize', updateVh);
    }
    return () => {
      updateVh.cancel();
      if (isBrowser()) {
        window.removeEventListener('resize', updateVh);
      }
    };
  }, []);
}
