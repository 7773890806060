import { Product, Rates } from 'pleinchamp-api-client';
import { ManipulateType } from 'dayjs';

export interface Filter {
  i18nKey: string;
  count: number;
  dateUnit: ManipulateType;
  stepUnit: ManipulateType;
}

export interface CattleProductType {
  cattleTypeId: number;
  products: Product[];
  rates: Rates;
}

export enum StocksHomeTabs {
  Crops = 'grandes-cultures',
  Cattle = 'betail-vif',
  Pork = 'porc',
  CattleFood = 'aliments-du-betail',
  Energies = 'intrants',
}

export enum StockSource {
  Agritel = 'Agritel',
  FMBV = 'FMBV',
  FinanceAgri = 'FinanceAgri',
}

export enum StockDetailsPath {
  Crops = 'grandes-cultures',
  Cattle = 'betail-vif',
  Pork = 'porc',
  CattleFood = 'aliments-du-betail',
  Energies = 'intrants',
  TermMarkets = 'marches-a-terme',
}
