interface ScriptAttribute {
  attribute: string;
  value?: string;
}
interface ScriptOptions {
  onLoad?: () => void;
  target?: Element;
}

function addScript(uri: string, attributes?: ScriptAttribute[], options?: ScriptOptions) {
  const newScript = document.createElement('script');
  newScript.setAttribute('src', uri);
  newScript.setAttribute('type', 'text/javascript');
  if (options?.onLoad) {
    newScript.onload = options.onLoad;
  }
  attributes?.forEach(({ attribute, value }) => value && newScript.setAttribute(attribute, value));

  if (options?.target) {
    options.target.appendChild(newScript);
  } else {
    document.head.appendChild(newScript);
  }
}

function removeScript(scriptId: string) {
  const head = document.getElementsByTagName('head')[0];
  const scriptToRemove = document.getElementById(scriptId);
  if (scriptToRemove != null) {
    head.removeChild(scriptToRemove);
  }
}

export { addScript, removeScript };
