import { TFunction } from 'next-i18next';
import {
  ActivityCode,
  ContentType,
  InterestCode,
  ProjectCode,
  SearchApi as SearchApiClient,
  SearchType,
  ServiceRatingCode,
} from 'pleinchamp-api-client';
import { ApiConfiguration, axiosInstance } from '@api/business/api.conf';
import { getApiEnumLabel } from '@api/business/api.utils';
import { Filter, FilterType, SearchContentType } from '@search/business/search.types';

const SearchApi = new SearchApiClient(ApiConfiguration, undefined, axiosInstance);

export function generateDefaultFilters(props?: { type?: FilterType; t?: TFunction }): Filter[] {
  const { type, t } = props || {};
  const themeFilters =
    typeof type === 'undefined' || type === FilterType.Themes
      ? Object.values(InterestCode).map(code => ({
          active: false,
          label: t ? getApiEnumLabel(code, t) : undefined,
          type: FilterType.Themes,
          value: code,
        }))
      : [];

  const activityFilters =
    typeof type === 'undefined' || type === FilterType.Activities
      ? Object.values(ActivityCode).map(code => ({
          active: false,
          label: t ? getApiEnumLabel(code, t) : undefined,
          type: FilterType.Activities,
          value: code,
        }))
      : [];

  return [...themeFilters, ...activityFilters];
}

export type SearchParams = {
  query?: string;
  contentTypes?: ContentType[];
  searchType?: SearchType;
  activities?: Array<ActivityCode>;
  interests?: Array<InterestCode>;
  projects?: Array<ProjectCode>;
  serviceRating?: ServiceRatingCode;
  isHighlighted?: boolean;
  isPro?: boolean;
  stateOrProvinceCodes?: Array<string>;
  exclude?: string;
  offset?: number;
  limit?: number;
  isSelectedByCa?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
};

export function getCustomJournalNews({ stateOrProvinceCodes, activities = [], interests = [] }: SearchParams) {
  const activitesWithoutNone = activities.filter(item => item.valueOf() !== 'none');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const argsList: any[] = [activitesWithoutNone, interests, stateOrProvinceCodes];

  return SearchApi.getCustomJournalNews(...argsList).then(response => response.data);
}

export function searchContent({
  query,
  contentTypes,
  searchType,
  activities = [],
  interests = [],
  projects,
  serviceRating,
  isHighlighted,
  isPro,
  stateOrProvinceCodes,
  exclude,
  offset,
  limit,
  options,
}: SearchParams) {
  const argsList = [
    query,
    contentTypes,
    searchType,
    activities,
    interests,
    projects,
    serviceRating,
    isHighlighted,
    isPro,
    stateOrProvinceCodes,
    exclude,
    offset,
    limit,
    options,
  ];
  return SearchApi.search(...argsList).then(response => response.data);
}

export function getSearchContentTypes(contentType?: SearchContentType | null) {
  switch (contentType) {
    case undefined:
    case null:
    case SearchContentType.All:
      return undefined;
    case SearchContentType.Service:
      return [ContentType.PlcService, ContentType.AffiliateService];
    default:
      return [contentType as unknown] as ContentType[];
  }
}
