import { TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import cookies from 'js-cookie';
import { AccountType, LightUser, Register, Resend, User } from 'pleinchamp-api-client';
import { useSelector } from 'react-redux';
import { RegistrationApi, UserApi } from '@api/business/api.utils';
import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';
import { PlcEffects, PlcReducers, RootState } from '@store/store';
import { usePlcRouter } from '@utils/customHooks';
import { isBrowser } from '@utils/server';
import { urlEnum } from '@utils/url';

export type UserState = {
  isInit: boolean;
  user?: User;
  errorCode?: number;
  accountType?: AccountType;
  isSSO?: boolean;
};

export const defaultState: UserState = { isInit: false };

const proAccountTypes = Object.values(AccountType).filter(at => at !== AccountType.Free);
const paidAccountTypes = [AccountType.Sec, AccountType.Cac, AccountType.BigAccount];
const paymentIframeEligibleAccountTypes = [AccountType.Sec, AccountType.Free];

export function isPro(accountType: AccountType) {
  return proAccountTypes.includes(accountType);
}

function isAffiliate(accountType: AccountType) {
  return [AccountType.Invited].includes(accountType);
}

export const userSelectors = {
  accountType: ({ user }: RootState) => user.accountType,
  getUser: ({ user }: RootState) => user.user,
  isAdmin: ({ user }: RootState) => Boolean(user.user && user.user.teamRole === 'admin'),
  isAffiliate: ({ user }: RootState) => Boolean(user.accountType && isAffiliate(user.accountType)),
  isCAC: ({ user }: RootState) => Boolean(user.user && user.user.accountType === AccountType.Cac),
  isInit: ({ user }: RootState) => user.isInit,
  isPro: ({ user }: RootState) => Boolean(user.accountType && isPro(user.accountType)),
  isSSO: ({ user }: RootState) => Boolean(user.isSSO),
  isSec: ({ user }: RootState) => Boolean(user.user && user.user.accountType === AccountType.Sec),
  isUserLoading: ({ loading }: RootState) => loading.effects.user.fetchUser,
  paymentIFrameEligible: ({ user }: RootState) =>
    Boolean(user.user && paymentIframeEligibleAccountTypes.includes(user.user.accountType)),
  paysForPro: ({ user }: RootState) => Boolean(user.user && paidAccountTypes.includes(user.user.accountType)),
  userProfessions: ({ user }: RootState) => (user.user && user.user.professions ? user.user.professions : []),
};

export function isSsoFromCookie() {
  const isSSO = cookies.get('sso');
  return Boolean(isSSO);
}

export function useIsSSO() {
  const router = usePlcRouter();
  const { pathname, query } = router;
  const isUserSSO = useSelector(userSelectors.isSSO);
  const isPageSSO = pathname === urlEnum.atriumSSO && !!query.loginTiers && !!query.jeton && !!query.AdresseIpSlc;
  return isUserSSO || isPageSSO;
}

export function isCguAccepted(user: User) {
  return isPro(user.accountType) ? user.isProTOSAgreed : user.isFreeTOSAgreed;
}

const reducers = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clearUser(_: UserState): UserState {
    return { ...defaultState, isInit: true };
  },
  setError(state: UserState, errorCode: number): UserState {
    return {
      ...state,
      errorCode,
    };
  },
  setIsInit(state: UserState, isInit = true): UserState {
    return { ...state, isInit };
  },
  setUser(state: UserState, user: User): UserState {
    return { ...state, accountType: user.accountType, errorCode: undefined, isInit: true, isSSO: user.isSSO, user };
  },
};

interface UserEffectsReturnType {
  fetchUser: (_?: never, rootState?: RootState) => Promise<void>;
  signUp: (payload: Register, rootState?: RootState) => Promise<void>;
  resendActivationLink: (payload: Resend, rootState?: RootState) => Promise<void>;
  putUser: (nextUser: LightUser, rootState?: RootState) => Promise<void>;
}

// Do not put PlcDispatch to avoid a circular dependency
const effects = (dispatch: any): UserEffectsReturnType => ({
  async fetchUser(_, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }
    try {
      const { data } = await UserApi.getAccount();
      TagCommanderService.updateVisitorConnectionState(data.accountType, isPro(data.accountType));
      if (isBrowser() && TagCommanderService.isConsented()) {
        cookies.set('accountType', data.accountType, {
          expires: 365,
          sameSite: 'strict',
        });
        if (data.isSSO) {
          cookies.set('sso', 'atrium', {
            expires: 365,
            sameSite: 'strict',
          });
        }
      }
      dispatch.user.setUser(data);
    } catch (e: any) {
      console.error('Error while fetching user', e);
      if (e.response) {
        dispatch.settings.setError(e.response.status);
      }
      await dispatch.auth.signOut();
    }
  },
  async putUser(updatedUserProps, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      dispatch.user.setIsInit();
      return;
    }
    try {
      const { data } = await UserApi.updateAccount(updatedUserProps);
      if (isBrowser() && TagCommanderService.isConsented()) {
        cookies.set('accountType', data.accountType, {
          expires: 365,
          sameSite: 'strict',
        });
        if (data.isSSO) {
          cookies.set('sso', 'atrium', {
            expires: 365,
            sameSite: 'strict',
          });
        }
      }
      dispatch.user.setUser(data);
    } catch (e: any) {
      console.error(e);
      dispatch.user.setIsInit();
      if (e.response) {
        dispatch.user.setError(e.response.status);
      }
      throw e;
    }
  },
  async resendActivationLink(payload, rootState) {
    if (!rootState) {
      return;
    }
    try {
      await RegistrationApi.resendActivation(payload);
    } catch (e: any) {
      console.error('Error while resending activation link', e);
      if (e.response) {
        dispatch.user.setError(e.response.status);
      }
      throw e;
    }
  },
  async signUp(payload, rootState) {
    if (!rootState) {
      return;
    }
    try {
      const { activities, interests, isPrefsSet } = rootState.pendingSettings.preferences;
      const register: Register = isPrefsSet
        ? {
            activities,
            interests,
            ...payload,
          }
        : payload;
      await RegistrationApi.register(register);
    } catch (e: any) {
      console.error('Error while sending registration', e);
      if (e.response) {
        dispatch.user.setError(e.response.status);
      }
      throw e;
    }
  },
});

export type UserReducersType = PlcReducers<UserState, typeof reducers>;
export type UserEffectsType = PlcEffects<typeof effects>;

export const user: ModelConfig<UserState> = createModel<UserState>({
  effects: effects as RematchDispatch,
  reducers,
  state: defaultState,
});
