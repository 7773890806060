import { SpacingKey, SpacingKeys } from './Spacing.style';
import debounce from 'lodash/debounce';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Constants } from '@utils/Constants';
import { isServer } from '@utils/server';
import classnames from 'classnames';
import { GeneralContext } from 'features/business/app.contexts';

export enum Breakpoint {
  s = 576,
  m = 768,
  l = 1024,
  xl,
}
type BreakpointKey = keyof typeof Breakpoint;

export function useWindowInnerWidth() {
  const localIsServer = isServer();
  const [width, setWidth] = useState<number>();
  const handleResize = debounce(() => {
    if (!localIsServer) {
      setWidth(window.innerWidth);
    }
  }, Constants.TEN_FRAMES);
  useEffect(() => handleResize(), [localIsServer]);
  useEffect(() => {
    handleResize();
    if (!localIsServer) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
}

export function useWindowInnerHeight() {
  const localIsServer = isServer();
  const [height, setHeight] = useState<number>();
  const handleResize = debounce(() => {
    if (!localIsServer) {
      setHeight(window.innerHeight);
    }
  }, Constants.TEN_FRAMES);
  useEffect(() => handleResize(), [localIsServer]);
  useEffect(() => {
    handleResize();
    if (!localIsServer) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return height;
}

export function useIsBreakpointDown(breakpoint: Breakpoint) {
  const { windowWidth } = useContext(GeneralContext);
  if (windowWidth) return windowWidth < breakpoint;
  return false;
}

export function useIsBreakpointUp(breakpoint: Breakpoint) {
  const { windowWidth } = useContext(GeneralContext);
  if (windowWidth) return windowWidth >= breakpoint;
  return false;
}

export function useBreakpoint() {
  const { windowWidth } = useContext(GeneralContext);
  if (windowWidth) {
    if (windowWidth <= Breakpoint.s) return Breakpoint.s;
    if (windowWidth <= Breakpoint.m) return Breakpoint.m;
    if (windowWidth <= Breakpoint.l) return Breakpoint.l;
  }
  return Breakpoint.xl;
}

type PlcClassNamesInput = {
  [key in BreakpointKey | 'all']?: string | (string | undefined)[];
};

export function generateClassNames(input: PlcClassNamesInput) {
  const classes = (Object.keys(input) as (keyof PlcClassNamesInput)[]).reduce((acc, breakpoint) => {
    const rawValue = input[breakpoint];
    if (!rawValue) {
      return acc;
    }
    const value = typeof rawValue === 'string' ? [rawValue] : rawValue;

    if (breakpoint === 'all') {
      acc.push(...value);
    } else {
      value.forEach(v => {
        if (!v) {
          return;
        }
        const valueParsed = v.split('-');
        const lastElement = valueParsed[valueParsed.length - 1];
        if (SpacingKeys.includes(lastElement as SpacingKey)) {
          valueParsed.splice(valueParsed.length - 1, 0, 'up', breakpoint);
        } else {
          valueParsed.push('up', breakpoint);
        }
        acc.push(valueParsed.join('-'));
      });
    }
    return acc;
  }, [] as (string | undefined)[]);
  return classnames(classes);
}

export function useClassNames(input: PlcClassNamesInput) {
  return useMemo(() => generateClassNames(input), [input]);
}

export function getBreakpointKey(breakpoint: Breakpoint) {
  const keys = Object.keys(Breakpoint);
  const index = Object.values(Breakpoint).findIndex(b => b === breakpoint);
  return keys[index];
}
