import { TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import { TagPlanService } from 'features/tagPlan/business/TagPlan.service';
import cookies from 'js-cookie';
import Router from 'next/router';
import { AccountType, Session as ClientSession } from 'pleinchamp-api-client';
import { SessionApi } from '@api/business/api.utils';
import { getAuthState, LOGOUT_KEY } from '@auth/business/auth.utils';
import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';
import { PlcDispatch, PlcEffects, PlcReducers, RootState } from '@store/store';
import { handleScrollTop } from '@utils/navigation';
import { urlEnum } from '@utils/url';

type Session = {
  sessionId?: string;
  cookie?: string;
  csrfToken?: string;
};

export type AuthState = Session & {
  isAuthenticated: boolean;
};

export const defaultState: AuthState = {
  cookie: undefined,
  csrfToken: undefined,
  isAuthenticated: false,
  sessionId: undefined,
};

function RouterPushAndScrollTop(...args: Parameters<typeof Router['push']>) {
  handleScrollTop();
  Router.push(...args);
}

export async function updateSessionData(dispatch: PlcDispatch, session: ClientSession) {
  cookies.set('token', JSON.stringify(session), {
    expires: 365,
    sameSite: 'strict',
  });
  const auth = getAuthState(session);
  return dispatch.auth.setIsAuthenticated({ ...auth });
}

async function logout(dispatch: PlcDispatch) {
  cookies.remove('token');
  cookies.remove('accountType');
  cookies.remove('sso');
  dispatch.auth.setIsAuthenticated(defaultState);
  dispatch.user.clearUser();
  dispatch.settings.clearSettings();
  dispatch.location.clearLocations();
  dispatch.services.clearServices();

  // Set item to localStorage to be catch by other tabs
  window.localStorage.setItem(LOGOUT_KEY, Date.now().toString());
  Router.reload();
}

const reducers = {
  setIsAuthenticated(state: AuthState, payload: AuthState): AuthState {
    return {
      ...state,
      ...payload,
    };
  },
};

type AuthEffectsReturnType = {
  signIn: (payload: { email: string; password: string; callbackUrl?: string }, rootState?: RootState) => Promise<void>;
  signOut: (_?: never, rootState?: RootState) => Promise<void>;
  refreshSession: (_?: never, rootState?: RootState) => Promise<void>;
};

// Do not put PlcDispatch to avoid a circular dependency
const effects = (dispatch: any): AuthEffectsReturnType => ({
  async refreshSession(_, rootState) {
    if (!rootState) {
      return;
    }
    const { sessionId, csrfToken } = rootState.auth;
    if (!sessionId) {
      throw Error('sessionId is missing');
    }
    if (!csrfToken) {
      throw Error('csrfToken is missing');
    }
    try {
      const res = await SessionApi.refresh(sessionId, csrfToken);
      await updateSessionData(dispatch, res.data.Session);
    } catch (err: any) {
      TagPlanService.unsetVisitor();
      await logout(dispatch);
      throw err;
    }
  },
  async signIn({ email, password, callbackUrl }) {
    TagPlanService.unsetVisitor();
    return (
      SessionApi.login({ SessionInput: { login: email, password } })
        .then(async res => updateSessionData(dispatch, res.data.Session))
        .then(async () => {
          // After registration we post the pending user preferences set if any
          await dispatch.settings.savePendingPreferences();
        })
        .then(() => {
          if (callbackUrl) {
            return callbackUrl;
          }

          if (([urlEnum.resetPassword, urlEnum.login] as string[]).includes(Router.route)) {
            // Specific case where we need to redirect to journal after login
            return urlEnum.journal;
          }
          return undefined;
        })
        // If the user will be redirected we first need to call fetchuser otherwise we just reload and fetchUser will be called by _app
        .then(async routeToPush => {
          if (routeToPush) {
            Promise.all([
              dispatch.user.fetchUser(),
              dispatch.settings.fetchPreferences(),
              dispatch.location.fetchFavoriteLocations(true),
            ]).then(() => RouterPushAndScrollTop(routeToPush));
          } else {
            Router.reload();
          }
        })
        .catch(err => {
          dispatch.auth.setIsAuthenticated(defaultState);
          throw err;
        })
    );
  },
  async signOut(_, rootState) {
    TagCommanderService.updateVisitorConnectionState(AccountType.Free, false);
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }
    const { sessionId, csrfToken } = rootState.auth;
    if (sessionId && csrfToken) {
      try {
        await SessionApi.logout(sessionId, csrfToken);
      } catch (err: any) {
        console.error(err);
      }
    }
    TagPlanService.unsetVisitor();
    await logout(dispatch);
  },
});

export type AuthEffectsType = PlcEffects<typeof effects>;

export type AuthReducersType = PlcReducers<AuthState, typeof reducers>;

const auth: ModelConfig<AuthState> = createModel<AuthState>({
  effects: effects as RematchDispatch,
  reducers,
  state: defaultState,
});

export { auth };
