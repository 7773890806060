import { Service } from 'pleinchamp-api-client';
import { UserApi } from '@api/business/api.utils';
import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';
import { PlcEffects, PlcReducers, RootState } from '@store/store';

export interface ServicesState {
  services: Service[];
  errorCode?: number;
}

export const defaultState = {
  services: [],
};

const reducers = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clearServices(_: ServicesState): ServicesState {
    return defaultState;
  },
  setServices(state: ServicesState, services: Service[]): ServicesState {
    return {
      ...state,
      errorCode: undefined,
      services,
    };
  },
};

interface ServicesEffectsReturnType {
  addUserService: (slug: Service['slug'], rootState?: RootState) => Promise<void>;
  fetchUserServices: (payload?: never, rootState?: RootState) => Promise<void>;
  removeUserService: (slug: Service['slug'], rootState?: RootState) => Promise<void>;
}

// Do not put PlcDispatch to avoid a circular dependency
const effects = (dispatch: any): ServicesEffectsReturnType => ({
  async addUserService(slug, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }
    try {
      const { services } = rootState.services;
      const isServiceAlreadyInFavorite = services.some(s => s.slug === slug);
      const { data: service } = await UserApi.insertUserService({ slug });
      const newServices = isServiceAlreadyInFavorite ? services.filter(s => s.slug !== slug) : [...services, service];
      await dispatch.services.setServices(newServices);
    } catch (e :any) {
      if (e.response) {
        dispatch.settings.setError(e.response.status);
      }
    }
  },
  async fetchUserServices(_, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }
    try {
      const { data: services } = await UserApi.getUserServices();
      dispatch.services.setServices(services);
    } catch (e: any) {
      if (e.response) {
        dispatch.settings.setError(e.response.status);
      }
    }
  },
  async removeUserService(slug, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }
    const {
      services: { services },
    } = rootState;
    try {
      await UserApi.deleteUserService(slug);
      const newServices = services.filter(s => s.slug !== slug);
      dispatch.services.setServices(newServices);
    } catch (e: any) {
      if (e.response) {
        dispatch.settings.setError(e.response.status);
      }
    }
  },
});

export type ServicesReducersType = PlcReducers<ServicesState, typeof reducers>;
export type ServicesEffectsType = PlcEffects<typeof effects>;

function createServicesModel(withEffects: boolean) {
  return createModel<ServicesState>({
    effects: withEffects ? (effects as RematchDispatch) : undefined,
    reducers,
    state: defaultState,
  });
}

export const services: ModelConfig<ServicesState> = createServicesModel(true);
