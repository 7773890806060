import { NextRouter, useRouter } from 'next/router';
import { handleScrollTop } from '@utils/navigation';

export function usePlcRouter(): NextRouter {
  const router = useRouter();

  function push(...args: Parameters<NextRouter['push']>) {
    handleScrollTop();
    return router.push(...args);
  }

  const routerCopy = { ...router, push };
  return routerCopy;
}
