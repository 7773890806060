export enum Spacing {
  xxs = 4,
  xs = 8,
  s = 12,
  m = 24,
  basis = 36,
  l = 48,
  xl = 72,
  xxl = 96,
}

export type SpacingKey = keyof typeof Spacing | '0';
export const SpacingKeys = [...Object.keys(Spacing), '0'] as SpacingKey[];
