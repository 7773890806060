import usePwa from 'features/pwa/pwa.hook';
import { TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import cookies from 'js-cookie';
import { appWithTranslation } from 'next-i18next';
import Router, { NextRouter } from 'next/router';
import React, { ComponentPropsWithRef, useEffect, useMemo, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { AdvertService } from '@adverts/business/Advert.service';
import { ADVERT_FORMAT_ID } from '@adverts/business/Adverts.contants';
import { updateAxiosInterceptors } from '@api/business/api.utils';
import { getAuthStateFromCookie } from '@auth/business/auth.utils';
import { Layout } from '@layout/components/Layout/Layout.component';
import GenericModal from '@layout/components/Modal/GenericModal.component';
import { GenericModalProps } from '@layout/components/Modal/Modal.types';
import { ThemeProvider } from '@material-ui/styles';
import { RematchStore } from '@rematch/core';
import { PlcDispatch, PlcStore, RootState } from '@store/store';
import { getOrCreateStore } from '@store/withRematch';
import { useWindowInnerWidth } from '@styles/breakpoints';
import { useUpdateVh } from '@styles/dimensions';
import { PlcMuiTheme } from '@styles/muiTheme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { usePlcRouter } from '@utils/customHooks';
import { getHost } from '@utils/navigation';
import { GeneralContext, GenericModalContext } from 'features/business/app.contexts';
import { PlcAppProps } from 'features/business/app.types';
import { eventOptions } from 'features/tagCommander/business/TagCommander.types';
import dynamic from 'next/dynamic';

import '../styles/reset.sass';
import '../styles/app.scss';
import '../styles/_main.scss';

const ReactQueryDevtools = dynamic(() =>
  import('@tanstack/react-query-devtools').then((module) => module.ReactQueryDevtools)
);

const Popin = dynamic<ComponentPropsWithRef<'div'>>(
  () => import('features/popin/components/Popin.component').then((module) => module.Popin),
  {
    ssr: false,
  }
);

function removeNoFocusOutline() {
  const bodyElement = document.querySelector('body');
  bodyElement?.classList.remove('no-focus-outline');
}

function useEventKey(keyName: string, keyCode: number, callback: () => void) {
  useEffect(() => {
    const keyEventListener = (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return;
      }
      const key = event.key || event.keyCode;
      if (key === keyName || key === keyCode) {
        callback();
      }
    };

    document.body.addEventListener('keyup', keyEventListener);

    return () => {
      document.body.removeEventListener('keyup', keyEventListener);
    };
  }, []);
}

const queryClient = new QueryClient();

function fetchUserInfo(state: RootState, dispatch: PlcDispatch, plcRouter: NextRouter) {
  // Add axios interceptors (refresh token) on the front side, on the first page load
  updateAxiosInterceptors(dispatch);

  if (!state.auth.isAuthenticated) {
    dispatch.user.setIsInit();
    // After the DISCONNECTION there is a reload of the website. So init is needed.
    TagCommanderService.updateVisitorConnectionState();
    TagCommanderService.init(plcRouter.pathname, plcRouter.asPath);
    return;
  }

  // When the promise is resolved we init TagCommander.
  // FYI : after clicking submit button in modal for connection the website is reload this why i choice this implementation in order to keep the legacy code
  dispatch.user.fetchUser().then(() => TagCommanderService.init(plcRouter.pathname, plcRouter.asPath));
  dispatch.settings.fetchPreferences();
  dispatch.location.fetchFavoriteLocations(true);
}

function MyApp({ Component, pageProps }: PlcAppProps) {
  const host = getHost() ?? 'https://www.pleinchamp.com';
  const genericModalRef = useRef<GenericModalProps>(null);
  const reduxStore = useMemo(() => getOrCreateStore(), []);
  const { getState, dispatch } = reduxStore as unknown as PlcStore;
  const windowWidth = useWindowInnerWidth();
  const [searchQuery, setSearchQuery] = useState('');
  const plcRouter = usePlcRouter();

  useEffect(() => {
    const token = cookies.get('token');
    if (token) {
      const session = getAuthStateFromCookie(token);
      const isCookieAuthenticated = session.isAuthenticated;
      const isStoreAuthenticated = getState().auth.isAuthenticated;
      if (isCookieAuthenticated !== isStoreAuthenticated) {
        dispatch.auth.setIsAuthenticated(session);
      }
    }
    fetchUserInfo(getState() as RootState, dispatch, plcRouter);
  }, []);

  useEffect(() => {
    // Remove material ui server side styles : https://medium.com/manato/ssr-with-next-js-styled-components-and-material-ui-b1e88ac11dfa
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles);
  }, []);

  useEffect(() => {
    AdvertService.start();

    const removeArchAdvert = () => AdvertService.clean(ADVERT_FORMAT_ID.Arch);
    const onRouteChange = () => {
      AdvertService.start();

      const eventData: eventOptions = {
        event: {
          context: TagCommanderService.getTcNavigationEvent(),
          label: 'page',
          variables: {},
        },
      };

      TagCommanderService.routeHandler(eventData);
    };
    Router.events.on('beforeHistoryChange', removeArchAdvert);
    Router.events.on('routeChangeComplete', onRouteChange);
    return () => {
      Router.events.off('beforeHistoryChange', removeArchAdvert);
      Router.events.off('routeChangeComplete', onRouteChange);
    };
  }, []);

  useEventKey('Tab', 9, removeNoFocusOutline);

  useUpdateVh();

  usePwa();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <GeneralContext.Provider value={{ host, searchQuery, setSearchQuery, windowWidth }}>
          <Provider store={reduxStore as RematchStore}>
            <ThemeProvider theme={PlcMuiTheme}>
              <GenericModalContext.Provider value={genericModalRef}>
                <Layout>
                  <Component {...pageProps} />
                  <GenericModal ref={genericModalRef} />
                  <Popin />
                </Layout>
              </GenericModalContext.Provider>
            </ThemeProvider>
          </Provider>
        </GeneralContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export type MyAppType = typeof MyApp;

export default appWithTranslation(MyApp);
