import { ComponentProps } from 'react';
import { AuthModal } from '@auth/components/AuthModal/AuthModal.component';
import { createModel, ModelConfig } from '@rematch/core';
import { PlcReducers } from '@store/store';

export enum AuthModalView {
  signIn,
  forgottenPassword,
  forgottenPasswordSuccess,
  forgottenEmail,
}

export interface AppBannerPayload {
  enabled: boolean;
  text: string;
}

export interface LayoutState {
  isMenuOpened: boolean;
  authModalView?: AuthModalView;
  authModalProps?: ComponentProps<typeof AuthModal>;
  userEmail?: string;
  appBanner: AppBannerPayload;
}

type AuthModalViewPayload = {
  authModalView: LayoutState['authModalView'];
  authModalProps?: LayoutState['authModalProps'];
  userEmail?: string;
};

const reducers = {
  setAppBanner(state: LayoutState, { enabled, text }: AppBannerPayload): LayoutState {
    return {
      ...state,
      appBanner: { enabled, text },
    };
  },
  setMenuOpened(state: LayoutState, isMenuOpened: LayoutState['isMenuOpened']): LayoutState {
    return {
      ...state,
      isMenuOpened,
    };
  },
  showAuthModalView(state: LayoutState, payload?: AuthModalViewPayload): LayoutState {
    if (!payload) {
      return {
        ...state,
        authModalProps: undefined,
        authModalView: undefined,
      };
    }
    return {
      ...state,
      ...payload,
    };
  },
  toggleMenuOpened(state: LayoutState): LayoutState {
    return {
      ...state,
      isMenuOpened: !state.isMenuOpened,
    };
  },
};

export type LayoutReducersType = PlcReducers<LayoutState, typeof reducers>;

const layout: ModelConfig<LayoutState> = createModel<LayoutState>({
  reducers,
  state: {
    appBanner: { enabled: false, text: '' },
    isMenuOpened: false,
    userEmail: '',
  },
});

export { layout };
