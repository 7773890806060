import { IDWithPriority } from 'pleinchamp-api-client';
import { LocationApi } from '@api/business/api.utils';
import { getUserLocations } from '@api/business/UserApi.utils';
import { City, StandardCity } from '@meteo/types';
import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';
import { PlcEffects, PlcReducers, RootState } from '@store/store';
import { fromCityToLightLocation, fromLocationToCity, getLastCity, setLastCity } from './meteo.utils';

export interface LocationState {
  favoriteLocations: StandardCity[];
  errorCode?: number;
  isInit: boolean;
  currentLocation?: City;
}

export const defaultState: LocationState = {
  favoriteLocations: [],
  isInit: false,
};

export const locationSelectors = {
  currentLocation: (rootSate: RootState) => rootSate.location.currentLocation,
  favoriteLocations: (rootSate: RootState) => rootSate.location.favoriteLocations,
  isInit: (rootState: RootState) => rootState.location.isInit,
};

const reducers = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clearLocations(_: LocationState): LocationState {
    return defaultState;
  },
  setCurrentLocation(state: LocationState, currentLocation: LocationState['currentLocation']): LocationState {
    return {
      ...state,
      currentLocation,
    };
  },
  setError(state: LocationState, errorCode: number): LocationState {
    return {
      ...state,
      errorCode,
    };
  },
  setLocations(state: LocationState, favoriteLocations: LocationState['favoriteLocations']): LocationState {
    return {
      ...state,
      errorCode: undefined,
      favoriteLocations,
      isInit: true,
    };
  },
};

interface SettingsEffectsReturnType {
  fetchFavoriteLocations: (forceReload?: boolean, rootState?: RootState) => Promise<LocationState['favoriteLocations']>;
  reorderLocations: (
    reorderedCities: StandardCity[],
    rootState?: RootState
  ) => Promise<LocationState['favoriteLocations']>;
  insertLocation: (newCity: StandardCity, rootState?: RootState) => Promise<LocationState['favoriteLocations']>;
}

// Do not put PlcDispatch to avoid a circular dependency
const effects = (dispatch: any): SettingsEffectsReturnType => ({
  async fetchFavoriteLocations(forceReload, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return [];
    }
    const { favoriteLocations: existingLocations, isInit } = rootState.location;
    if (isInit && !forceReload) {
      return existingLocations;
    }
    try {
      const newLocations = await getUserLocations(rootState.auth.isAuthenticated);
      dispatch.location.setLocations(newLocations);
      if (newLocations.length) {
        const lastCity = getLastCity();
        if (!lastCity) {
          setLastCity(newLocations[0]);
          dispatch.location.setCurrentLocation(newLocations[0]);
        }
      }
      return newLocations;
    } catch (e: any) {
      if (e.response) {
        dispatch.location.setError(e.response.status);
      }
      return [];
    }
  },
  async insertLocation(newCity: StandardCity, rootState?: RootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return [];
    }
    const newLocation = fromCityToLightLocation(newCity);
    if (!newLocation) return [];

    try {
      const { data } = await LocationApi.insertLocation(newLocation);
      // @ts-ignore
      const newLocations = [...rootState.location.favoriteLocations, fromLocationToCity(data)];
      dispatch.location.setLocations(newLocations);
      return newLocations;
    } catch (e: any) {
      if (e.response) {
        dispatch.location.setError(e.response.status);
      }
      return [];
    }
  },
  async reorderLocations(reorderedCities, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return [];
    }
    const ids = reorderedCities.reduce<IDWithPriority[]>((previous, l, index) => {
      previous.push({
        // A location ID is a citycode StandardCity (see fromLocationToCity method)
        id: Number(l.properties.citycode),
        priority: index,
      });
      return previous;
    }, []);
    try {
      await LocationApi.reorderLocations(ids);
      dispatch.location.setLocations(reorderedCities);
      return reorderedCities;
    } catch (e: any) {
      if (e.response) {
        dispatch.location.setError(e.response.status);
      }
      return [];
    }
  },
});

export type LocationReducersType = PlcReducers<LocationState, typeof reducers>;
export type LocationEffectsType = PlcEffects<typeof effects>;

export const location: ModelConfig<LocationState> = createModel<LocationState>({
  effects: effects as RematchDispatch,
  reducers,
  state: defaultState,
});
