import { RefObject, useContext } from 'react';
import { ModalProps } from '@layout/components/Modal/Modal.types';
import { isServer } from '@utils/server';
import { GenericModalContext } from 'features/business/app.contexts';

export function useModal() {
  const modalRef = useContext(GenericModalContext);

  function show(props: Partial<ModalProps>) {
    if (modalRef?.current) {
      modalRef.current.show(props);
    }
  }

  function hide() {
    if (modalRef?.current) {
      modalRef.current.hide();
    }
  }

  return {
    hide,
    show,
  };
}
const HEADER_HEIGHT_L = 96;
export function scrollToElement<T extends HTMLElement>(elementRef?: RefObject<T>) {
  if (!elementRef?.current || isServer()) {
    return;
  }
  const top = elementRef.current.offsetTop - (HEADER_HEIGHT_L + 24);
  window.scrollTo({ behavior: 'smooth', top });
}
