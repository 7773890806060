import { initializeStore, RootState, Store } from './store';
import { isServer } from '@utils/server';

const NEXT_REDUX_STORE = '__NEXT_REDUX_STORE__';

function getOrCreateStore(initialState: Partial<RootState> = {}): Store {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer()) {
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  // @ts-ignore
  if (!window[NEXT_REDUX_STORE]) {
    // @ts-ignore
    window[NEXT_REDUX_STORE] = initializeStore(initialState);
  }
  // @ts-ignore
  return window[NEXT_REDUX_STORE];
}

export { getOrCreateStore };
